import color from "./color";
// import dimen from "./Dimensions";
import string from "./strings/String";
import image from "./images/image";

export default {
    color,
    // dimen,
    string,
    image
};